<template>
  <Menu />
</template>

<script>
import Menu from '@/components/Menu.vue'

export default {
  name: 'App',
  components: {
    Menu
  }
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

body {
  padding-top: 3.75rem;
}

hr.underliner {
  border-top: solid 5px $saltus-green;
  margin: 10px auto 20px;
  width: 10%;
}

a {
  color: $saltus-green;
}

a:hover {
  color: $saltus-green-hover;
}

.btn-saltus {
  background-color: $saltus-green;
  border-color: $saltus-green;
  color: #fff;
  border-radius: 0;
}

.btn-saltus:hover {
  color: #fff;
  background-color: $saltus-green-hover;
  border-color: $saltus-green-hover;
}

.saltus-green-color {
  color: $saltus-green;
}

.saltus-yellow-color {
  color: $saltus-yellow;
}

.bg-white {
  background-color: #fff !important;
}

ul {
  list-style: none;
}

ul.first li:before {
  content: '';
  display: inline-block;
  height: 1em;
  width: 1em;
  background-image: url('./assets/img/right-chevron-green.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.5em;
}

ul.second li:before {
  content: '';
  display: inline-block;
  height: 1em;
  width: 1em;
  background-image: url('./assets/img/right-chevron-yellow.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.5em;
}

.go-down a,
.go-up a {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: inline-block;
  padding: 10px;
  text-shadow: none;
}

.go-down a {
  background: #fff;
  color: $saltus-green;
}

.go-up a {
  background: $saltus-green;
  color: #fff;
}

.go-down a:hover {
  background: #fff;
  color: $saltus-green-hover;
}

.go-up a:hover {
  background: $saltus-green-hover;
  color: #fff;
}
</style>
