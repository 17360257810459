<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-white">
    <div class="container-fluid">
      <a href="#top" class="navbar-brand" title="SALTUS"
        ><img
          alt="SALTUS"
          src="@/assets/img/saltus-logo-green.svg"
          height="30"
          loading="lazy"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a class="nav-link" href="#about" title="About us" v-smooth-scroll
              >About us</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#products"
              title="Products"
              v-smooth-scroll
              >Products</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#gfa"
              title="Geared Front Attachment"
              v-smooth-scroll
              >Geared Front Attachment</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#sockets"
              title="Sockets & Bits"
              v-smooth-scroll
              >Sockets & Bits</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#care"
              title="Care and Maintenance"
              v-smooth-scroll
              >Care and Maintenance</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<script>
export default {
  name: 'Menu'
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: $saltus-green;
}
</style>
