<template>
  <div class="digital-twin-form">
    <form id="twin-form" v-on:submit.prevent="submitForm">
      <div class="input-group mb-3">
        <input
          id="serialNumber"
          v-model="form.serial"
          type="search"
          class="form-control"
          placeholder="Type Your Serial Number"
        />
        <div class="input-group-append">
          <button class="btn btn-saltus" type="submit">Search</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService.js'
import { Modal } from 'bootstrap'

export default {
  name: 'DigitalTwinForm',
  data() {
    return {
      form: {
        serial: '',
        type: 'standard',
        locale: 'en'
      }
    }
  },
  methods: {
    submitForm() {
      ApiService.check(this.form)
        .then(response => {
          window.open(response.data.uri)
        })
        .catch(error => {
          if (error.response) {
            if (404 === error.response.status) {
              var myModalEl = document.getElementById('notAvailable')
              var myModal = new Modal(myModalEl, {})
              myModalEl.addEventListener('show.bs.modal', function() {
                const modalBodyParagraph = myModalEl.querySelector('p#message')
                modalBodyParagraph.innerText = error.response.data.detail
              })
              myModal.show()
            }
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.form-control {
  border-radius: 0;
}

.form-control:focus {
  border-color: $saltus-green;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 122 87 / 25%);
}
</style>
