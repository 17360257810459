<template>
  <div v-if="index === 0" class="carousel-item active" data-interval="3000">
    <img
      class="d-block w-100"
      :src="resolve_img_url(image.path)"
      :alt="image.name"
    />
    <div class="carousel-caption d-none d-md-block">
      <h5>{{ image.title }}</h5>
    </div>
  </div>
  <div v-else class="carousel-item" data-bs-interval="3000">
    <img
      class="d-block w-100"
      src="https://place-hold.it/1240x874"
      :data-src="resolve_img_url(image.path)"
      :alt="image.name"
    />
    <div class="carousel-caption d-none d-md-block">
      <h5>{{ image.title }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageCarousel',
  props: {
    index: {
      type: Number,
      required: true
    },
    image: {
      type: Object,
      required: true
    }
  },
  methods: {
    resolve_img_url: function(path) {
      let images = require.context('@/assets/img/', true, /^.*\.png$/)
      return images('./' + path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.carousel-caption {
  color: $saltus-green;
}
</style>
