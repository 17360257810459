<template>
  <div class="text-center mt-5">
    <h1>Page Not Found</h1>
    <p>Sorry, but the page you were trying to view does not exist.</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped></style>
