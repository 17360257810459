<template>
  <div id="gfa" class="row p-md-5 pt-5">
    <div class="col-12 mb-3">
      <h2 class="text-center mt-3">Saltus Geared Front Attachment</h2>
      <hr class="underliner" />
    </div>
    <div class="col-12 mb-3">
      <p>
        Space-restricted tightening environments and other challenging
        applications make assembly increasingly strenuous. These accessibilty
        issues can be addressed with the use of designated fastening systems and
        this is where Saltus Geared Front Attachments come in. Thanks to our
        large portfolio of GFAs, with different dimensions, maximum output
        torque and number of gear stages, we will always offer the best solution
        to your application challenges.
      </p>
      <p>
        GFA solution for HAD includes pre-torque, shear-off, torque control with
        hexagonal or ASTER bits. Output gear extension can be set to any value
        and installed upward or downward.
      </p>
    </div>
    <div class="col-12 mb-5">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-6 col-lg-4">
          <div
            id="mycarousel"
            class="carousel slide lazy border"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <ImageCarousel
                v-for="(image, index) in AllImages"
                :key="image.id"
                :image="image"
                :index="index"
              />
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#mycarousel"
              data-bs-slide="prev"
            >
              <span class="carousel-control-prev-icon"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#mycarousel"
              data-bs-slide="next"
            >
              <span class="carousel-control-next-icon"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-8">
          <div class="row row-cols-1 row-cols-md-3 mb-4">
            <div class="col">
              <div class="card text-center">
                <img
                  src="@/assets/img/Closed-Ended-Crowfoot.jpg"
                  class="card-img-top"
                  alt="Closed-Ended Crowfoot"
                />
                <div class="card-body">
                  <h5 class="card-title">
                    Closed-Ended Crowfoot
                  </h5>
                  <p class="card-text">
                    Up to 75Nm output torque<br />
                    GFA offset closed-end solutions for cramped areas and
                    Hi-Lite applications paired with output gear models to
                    install not only regular bolts and nuts, but also
                    Hold-and-Drive, frangible collars, and socket screws. With
                    an optimized design of the GFAs, we offer the best
                    performance-to-size ratio, productivity and ergonomics.
                  </p>
                  <a
                    href="#"
                    class="btn btn-saltus"
                    data-bs-toggle="modal"
                    data-bs-target="#closed-ended-crowfoot"
                    >Learn more</a
                  >
                </div>
              </div>
              <div class="modal fade" id="closed-ended-crowfoot" tabindex="-1">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Closed-Ended Crowfoot</h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12 col-lg-6 align-self-center">
                          <img
                            alt="Closed-Ended Crowfoot"
                            class="img-fluid"
                            src="@/assets/img/Closed-Ended-Crowfoot.jpg"
                          />
                        </div>
                        <div
                          class="col-md-12 col-lg-6 d-flex align-items-center"
                        >
                          <div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Low Gear Modules
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Better efficiency<br />
                                  Better accuracy<br />
                                  Less I/P torque required for the same O/P
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Modular crowfoot interface
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Same interface for both fixed and indexing
                                  adaptors
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Indexing engagement indicator
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Warning indicates that the splines are not
                                  engaged
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Patented threaded bearing housing
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Ultra compact design<br />
                                  Improved 1st gear guidance
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Exclusive steel for the gears
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Better durability/efficiency
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Performance focus
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Run-in gears equivalent to 6000 tightening
                                  before delivery
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Magnet in crowfoot housing
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Better durability
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Upward and downward socket assembly direction
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  No housing difference<br />
                                  Easy reconfiguration
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  available with or without hold & drive output
                                  socket
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  No housing difference<br />
                                  Easy reconfiguration
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card text-center">
                <img
                  src="@/assets/img/Open-Ended-Crowfoot.jpg"
                  class="card-img-top"
                  alt="Open-Ended Crowfoot"
                />
                <div class="card-body">
                  <h5 class="card-title">
                    Open-Ended Crowfoot
                  </h5>
                  <p class="card-text">
                    Up to 75Nm output torque<br />GFA offset open-end heads for
                    tube-nut & cable-nut applications that come in many
                    different sizes with a variable number of gear stages,
                    maximum output torque and there are also counter clockwise
                    models for left-threaded connections. These can be combined
                    with various output gear models to install tube-nuts, B-nuts
                    or cable-nuts with hexagonal or double hexagonal profiles.
                    The output gears can be installed upward or downward and the
                    extension set to any value for even better accessibility to
                    the joint.
                  </p>
                  <a
                    href="#"
                    class="btn btn-saltus"
                    data-bs-toggle="modal"
                    data-bs-target="#open-ended-crowfoot"
                    >Learn more</a
                  >
                </div>
              </div>
              <div class="modal fade" id="open-ended-crowfoot" tabindex="-1">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Open-Ended Crowfoot</h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12 col-lg-6 align-self-center">
                          <img
                            alt="Open-Ended Crowfoot"
                            class="img-fluid"
                            src="@/assets/img/Open-Ended-Crowfoot.jpg"
                          />
                        </div>
                        <div
                          class="col-md-12 col-lg-6 d-flex align-items-center"
                        >
                          <div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Conveniently Positioned re-indexation
                                  mechanism
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  For easy maintenance<br />
                                  Compact design
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Patented threaded bearing housing
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Ultra-compact design<br />
                                  Improved 1st gear guidance
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Chamfered slot
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>For easy maintenance</small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Low Gear Modules
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Better efficiency<br />
                                  Better accuracy<br />
                                  Less I/P torque is required for the same O/P
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Same socket can be assembled in both
                                  directions upward and downward
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Easy Reconfiguration
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Magnet in crowfoot housing
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Restricts metallic fragments from entering to
                                  the gear mechanism<br />
                                  Improves durability
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Exclusive high-quality steel for critical
                                  components
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  For industry-leading durability and efficiency
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card text-center">
                <img
                  src="@/assets/img/AH110deg.jpg"
                  class="card-img-top"
                  alt="AH110°"
                />
                <div class="card-body">
                  <h5 class="card-title">
                    AH110°
                  </h5>
                  <p class="card-text">
                    4 models - Up to 90Nm<br />An angle head with 110 degrees
                    gives better accessibility to cramped working areas and it
                    improves the ergonomics for the operator.
                  </p>
                  <a
                    href="#"
                    class="btn btn-saltus"
                    data-bs-toggle="modal"
                    data-bs-target="#ah110"
                    >Learn more</a
                  >
                </div>
              </div>
              <div class="modal fade" id="ah110" tabindex="-1">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">AH110°</h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-12 col-lg-6 align-self-center">
                          <img
                            alt="AH110°"
                            class="img-fluid"
                            src="@/assets/img/AH110deg.jpg"
                          />
                        </div>
                        <div
                          class="col-md-12 col-lg-6 d-flex align-items-center"
                        >
                          <div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Robust integration with the tool
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Short & light tool for better ergonomics
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Outer thread
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  For easy GFA attachment
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Magnet in the angle head housing
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small
                                  >Restricts metallic fragments from entering to
                                  the gear mechanism<br />
                                  Improves durability</small
                                >
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Optimized first-gear contact
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  Improves efficiency
                                </small>
                              </p>
                            </div>
                            <div class="mb-3 position-relative">
                              <div class="text-uppercase">
                                <h5 class="ps-5 mb-1">
                                  Exclusive high-quality steel for critical
                                  components
                                </h5>
                              </div>
                              <p class="ps-5 text-secondary">
                                <small>
                                  For industry-leading durability and efficiency
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-4">
          <div class="row row-cols-1 row-cols-md-2 mb-4">
            <div class="col">
              <div class="card text-center">
                <img
                  src="@/assets/img/HIT.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h5 class="card-title">
                    HIT
                  </h5>
                  <p class="card-text">
                    In-line version dedicated to pistol tools. Perfect for
                    difficult accesss areas.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card text-center">
                <img
                  src="@/assets/img/Accessories.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h5 class="card-title">
                    Accessories
                  </h5>
                  <p class="card-text">Hold & Drive solutions <br />Adaptors</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageService from '@/services/ImageService.js'
import ImageCarousel from '@/components/ImageCarousel.vue'

export default {
  name: 'Gfa',
  components: {
    ImageCarousel
  },
  data() {
    return {
      AllImages: ImageService.getAllImages()
    }
  },
  mounted() {
    const myCarousel = document.getElementById('mycarousel')
    myCarousel.addEventListener('slide.bs.carousel', function(e) {
      let target = e.relatedTarget
      let img
      img = target.querySelectorAll('img[data-src]').item(0)
      img.setAttribute('src', img.dataset.src)
      img.removeAttribute('data-src')
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.modal-content {
  border-radius: 0;
}
#mycarousel {
  background-color: $saltus-green-blur;
}
</style>
