import { createApp } from 'vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAward,
  faCheckDouble,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronUp,
  faCogs,
  faNetworkWired,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import VueSmoothScroll from 'v-smooth-scroll'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createGtm } from '@gtm-support/vue-gtm'

library.add(
  faAward,
  faCheckDouble,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronUp,
  faCogs,
  faNetworkWired,
  faUsers
)

createApp(App)
  .use(store)
  .use(router)
  .use(VueSmoothScroll)
  .use(
    createGtm({
      id: 'GTM-T7CFCZR',
      vueRouter: router
    })
  )
  .component('fa', FontAwesomeIcon)
  .mount('#app')
