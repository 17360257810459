<template>
  <div id="products" class="row p-md-5 pt-5">
    <div class="col-12 mb-3">
      <h2 class="text-center mt-3">Products</h2>
      <hr class="underliner" />
    </div>
    <div class="col-12">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="card text-center border-white">
            <img
              src="@/assets/img/gfa-products.jpg"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">
                Geared Front Attachment
              </h5>
              <a
                href="#gfa"
                class="btn btn-saltus"
                title="Geared Front Attachment"
                v-smooth-scroll
                >Learn more</a
              >
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="card text-center border-white">
            <img
              src="@/assets/img/sockets-bits-products.jpg"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">
                Sockets & Bits
              </h5>
              <a
                href="#sockets"
                class="btn btn-saltus"
                title="Sockets &amp; Bits"
                v-smooth-scroll
                >Learn more</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Products'
}
</script>

<style scoped></style>
