<template>
  <div id="timeline">
    <ol>
      <li>
        <div>
          <time>1919</time>
          Founded as a metalworking business in Solingen, Germany
          <img
            src="@/assets/img/saltus-solingen-factory.jpg"
            class="img-fluid mt-2"
            alt="SALTUS, founded as a metalworking business in Solingen, Germany"
            loading="lazy"
          />
        </div>
      </li>
      <li>
        <div>
          <time>1938</time>
          Patented the world's first click-type torque wrench
        </div>
      </li>
      <li>
        <div>
          <time>1995</time>
          Patented first electronic torque wrench with angle measurement not
          requiring a reference arm.
        </div>
      </li>
      <li>
        <div>
          <time>2004</time>
          Patented first mechatronic torque wrench
        </div>
      </li>
      <li>
        <div>
          <time>2013</time>
          Acquired by the Atlas Copco Group. Saltus produced assembly wrenches,
          manual tools, knifes , sockets and bits
        </div>
      </li>
      <li>
        <div>
          <time>2017</time>
          Closure of the operation in Germany and relocation of production to
          Tierp, Sweden
          <img
            src="@/assets/img/saltus-tierp-factory.jpg"
            class="img-fluid mt-2"
            alt="Closure of the operation in Germany and relocation of production to
          Tierp, Sweden"
            loading="lazy"
          />
        </div>
      </li>
      <li>
        <div>
          <time>2018</time>
          The Saltus brand is solely focusing on Attachments – Sockets and bits
          and Geared Front Attachments
        </div>
      </li>
      <li>
        <div>
          <time>2020</time>
          Major launch of Geared Front Attachments
        </div>
      </li>
      <li></li>
    </ol>

    <div class="arrows">
      <button class="arrow arrow__prev disabled" disabled>
        <span class="saltus-green-color">
          <fa icon="chevron-circle-left" size="2x"></fa>
        </span>
      </button>
      <button class="arrow arrow__next">
        <span class="saltus-green-color">
          <fa icon="chevron-circle-right" size="2x"></fa>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Timeline',
  mounted() {
    // this.setEqualHeights(),
    this.animateTl()
  },
  methods: {
    setEqualHeights() {
      const el = document.querySelectorAll('#timeline li > div')
      let counter = 0
      for (let i = 0; i < el.length; i++) {
        const singleHeight = el[i].offsetHeight
        if (counter < singleHeight) {
          counter = singleHeight
        }
      }

      for (let i = 0; i < el.length; i++) {
        el[i].style.height = `${counter}px`
      }
    },
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      )
    },
    setBtnState(el, flag = true) {
      const disabledClass = 'disabled'
      if (flag) {
        el.classList.add(disabledClass)
      } else {
        if (el.classList.contains(disabledClass)) {
          el.classList.remove(disabledClass)
        }
        el.disabled = false
      }
    },
    animateTl() {
      const scrolling = 200
      const el = document.querySelectorAll('#timeline .arrows .arrow')
      const tl = document.querySelector('#timeline ol')
      const firstItem = document.querySelector('#timeline li:first-child')
      const lastItem = document.querySelector('#timeline li:last-child')
      const arrowPrev = document.querySelector('#timeline .arrows .arrow__prev')
      const arrowNext = document.querySelector('#timeline .arrows .arrow__next')
      let _this = this
      let counter = 0
      for (let i = 0; i < el.length; i++) {
        el[i].addEventListener('click', function() {
          if (!arrowPrev.disabled) {
            arrowPrev.disabled = true
          }
          if (!arrowNext.disabled) {
            arrowNext.disabled = true
          }
          const sign = this.classList.contains('arrow__prev') ? '' : '-'
          if (counter === 0) {
            tl.style.transform = `translateX(-${scrolling}px)`
          } else {
            const tlStyle = getComputedStyle(tl)
            const tlTransform =
              tlStyle.getPropertyValue('-webkit-transform') ||
              tlStyle.getPropertyValue('transform')
            const values =
              parseInt(tlTransform.split(',')[4]) +
              parseInt(`${sign}${scrolling}`)
            tl.style.transform = `translateX(${values}px)`
          }
          _this.setTimeout(firstItem, lastItem, arrowPrev, arrowNext)

          counter++
        })
      }
    },
    setTimeout(firstItem, lastItem, arrowPrev, arrowNext) {
      this.isElementInViewport(firstItem)
        ? this.setBtnState(arrowPrev)
        : this.setBtnState(arrowPrev, false)
      this.isElementInViewport(lastItem)
        ? this.setBtnState(arrowNext)
        : this.setBtnState(arrowNext, false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

#timeline {
  white-space: nowrap;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.1);
}

#timeline ol {
  font-size: 0;
  width: 100vw;
  padding: 410px 0 400px;
  transition: all 1s;
}

#timeline ol li {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 200px;
  height: 6px;
  background: $saltus-green;
}

#timeline ol li:last-child {
  width: 280px;
}

#timeline ol li:not(:first-child) {
  margin-left: 22px;
}

#timeline ol li:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  left: calc(100% + 1px);
  bottom: 0;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: $saltus-yellow;
}

#timeline ol li div {
  position: absolute;
  left: calc(100% + 7px);
  width: 350px;
  padding: 15px;
  font-size: 1rem;
  white-space: normal;
  background: $saltus-green;
  color: #fff;
}

#timeline ol li div::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

#timeline ol li:nth-child(odd) div {
  top: -16px;
  transform: translateY(-100%);
}

#timeline ol li:nth-child(odd) div::before {
  top: 100%;
  border-width: 8px 8px 0 0;
  border-color: $saltus-green transparent transparent transparent;
}

#timeline ol li:nth-child(even) div {
  top: calc(100% + 16px);
}

#timeline ol li:nth-child(even) div::before {
  top: -8px;
  border-width: 8px 0 0 8px;
  border-color: transparent transparent transparent $saltus-green;
}

#timeline time {
  display: block;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 8px;
}

#timeline button {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

#timeline .arrows {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

#timeline .arrows .arrow__prev {
  margin-right: 20px;
}

#timeline .disabled {
  opacity: 0.5;
}

#timeline .arrows img {
  width: 45px;
  height: 45px;
}

@media (max-width: 767px) {
  #timeline ol,
  #timeline ol li {
    width: auto;
  }

  #timeline ol {
    padding: 0;
    transform: none !important;
  }

  #timeline ol li {
    display: block;
    height: auto;
    background: transparent;
  }

  #timeline ol li:first-child {
    margin-top: 25px;
  }

  #timeline ol li:not(:first-child) {
    margin-left: auto;
  }

  #timeline ol li div {
    width: 94%;
    height: auto !important;
    margin: 0 auto 25px;
  }

  #timeline ol li div {
    position: static;
  }

  #timeline ol li:nth-child(odd) div {
    transform: none;
  }

  #timeline ol li:nth-child(odd) div::before,
  #timeline ol li:nth-child(even) div::before {
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    border: none;
    border-left: 6px solid #ffcb05;
    height: 25px;
  }

  #timeline ol li:last-child,
  #timeline ol li:nth-last-child(2) div::before,
  #timeline ol li:not(:last-child)::after,
  #timeline .arrows {
    display: none;
  }
}
</style>
